.spareProducts {
	padding: 150px 0;

	.spareProducts_wrap {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		gap: 30px;
	}
}

@media (max-width: 500px) {
	.spareProducts {
		padding-bottom: 50px;

		.spareProducts_wrap {
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;
		}
	}
}
